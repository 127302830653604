export default {
    //home页 头部
    header:{
        login:{

        },
        unLogin:{
            loginTip:'साइन अप के बाद नकद निकालें।',
            loginBtn:'साइन इन करें'
        }
    },
    lottery:{
        unStart:{
            tip1:"भाग्यशाली लोग अधिकतम कमा सकते हैं।",
            tip2:"₹999 नकद",
        },
        result:{
            lastTimeShareGet:"पिछले आमंत्रण से अर्जित नकद：",
            amount:"Available cash amount",
            canWithdraw1:"₹200 वापस लिया जा सकता है।",
            canWithdraw2:"₹10 वापस लिया जा सकता है।",
        }
    },
    bottom:{
        lotteryBtn: {
            desc:"अधिक निकासी सीमा प्राप्त करने के लिए स्पिन पर क्लिक करें.",
            detail:"अभिलेख",
            rules:"नियमों"
        }
    },
    popUp: {
        login:{
            login:"साइन इन करें",
            phone:"फ़ोन नंबर",
            input:"कृपया अपना फोन नंबर दर्ज करेंं",
            upiInput:"कृपया upi नंबर दर्ज करें",
            agree:"मैं इसके लिए सहमत हूँ",
            term:"उपयोग की शर्तें",
            and:"तथा",
            policy:"गोपनीयता नीति",
            sure:"सही",
            detail:"अपंजीकृत उपयोगकर्ता स्वचालित रूप से वृष राशि पर पंजीकृत हो जाएंगे।"
        },
        canWithdraw1: {
            sure:"निकासी अभी उपलब्ध है",
            detail:"यूनतम निकासी राशि तक पहुँच गया था। जाओ अपना कैश निकालो!",
            goTo:"नकदी वापिस लेना"
        }
        ,
        launch:{
            success:"निकासी प्रस्तुत",
            desc:"विस्मयकारी! एक और स्पिन प्रदान की जाती है, क्योंकि आपके रेफ़रल उच्च गुणवत्ता वाले होते हैं। स्पिन करें और नकद निकालें!",
            start:"नकद जीतने के लिए स्पिन "
        },
        warm:{
            tip:"अनुकूल सूचना",
            desc1:"अपना रेफरल लिंक साझा करें। अगर आपके दोस्त रजिस्टर करते हैं और खेलते हैं तो हमेशा के लिए नकद कमाएं। ",
            desc2:"सूचना: न्यूनतम निकासी राशि बढ़कर ₹1000 हो गई थी!",
            desc3:"समझा"
        },
        share:{
            detail:"सफलतापूर्वक साझा करें ",
            distance1:"दूर। ",
            distance2:"निकासी से केवल ",
            invite:"अधिक आमंत्रित करें"
        }
        ,
        getRupeeAgain:{
            header:"बधाई हो",
            desc:"बधाई हो! आपकी शेष राशि में नकद जोड़ दिया गया था। एक कदम पीछे हटने के करीब!",
            share:"Invite friends and earn cash",
        }
    }
    ,
    bottomBtn: {
        draw:{
            how:"पैसे कैसे कमाए और निकाले ?",
            way1:"1. रेफरल लिंक साझा करें और दोस्तों को रजिस्टर करने के लिए आमंत्रित करें ",
            way2: "2. दोस्तों के रीचार्ज के बाद 30% तक कैशबैक पाएं",
        }
    },
    income:{
        detail:"नकद रिकॉर् ",
        date:"दिनांक",
        income: "नकद"
    },
    rule:{
        step:"नियमों",
        step1:"अपने रेफरल लिंक को दोस्तों के साथ साझा करके नकद कमाएं। दोस्तों के रजिस्ट्रेशन के तुरंत बाद आपके बैलेंस में कैश क्रेडिट कर दिया जाएगा। दोस्तों को आमंत्रित करें और अभी नकद कमाएं!",
        step2:"न्यूनतम निकासी राशि की आवश्यकता है। यदि आपकी शेष राशि निश्चित राशि तक नहीं पहुंचती है तो आप निकासी नहीं कर पाएंगे।.",
        step3:"निषिद्ध व्यवहार वाले किसी भी खाते को स्थायी रूप से अवरुद्ध कर दिया जाएगा। खाता अवरुद्ध होने से होने वाले किसी भी नुकसान के लिए  Taurus जिम्मेदार नहीं है।"
    }
}
