import Vue from 'vue';
import VueI18n from 'vue-i18n';

import Locale from '@/utils/locale';

const moduleFiles=require.context("@/i18n/lang",false,/.js$/);
let module=moduleFiles.keys().reduce((modules,modulePath)=>{
    let lang=modulePath.replace("./","").replace(".js","");
    let value=moduleFiles(modulePath).default ;
    let o={};
    o[lang]=value;
    Object.assign(modules, o);
    return modules;
},{});
const messages = {
    en:module.en,
    hi:module.hi
};

class I18n extends VueI18n {
    constructor(options) {
        super(options);
    }
}

Vue.use(I18n);

const i18n = new I18n({
    locale: 'en',
    messages,
    silentTranslationWarn: true,
});

i18n.locale = Locale.getLocale();

const translate = key => {
    if (!key) {
        return '';
    }
    return i18n.t(key);
};
i18n.translate = translate;

export default i18n;
