import { production } from '../config';

class Logger {
    error() {}

    static keyNames = {
        'tc_home_display_001': '首页展示',
        'tc_home_back_002': '首页-点击返回',
        'tc_home_language_003': '首页-点击多语音',
        'tc_home_rule_004': '首页-点击规则',
        'tc_home_customer_005': '首页-点击客服',
        'tc_home_display_006': '首页-点击提现',
        'tc_home_display_007': '首页-点击商城',
        'tc_home_display_008': '首页-点击等级提示',
        'tc_home_display_009': '首页-点击我的奖励',
        'tc_home_display_010': '首页-点击我的代理',
        'tc_home_display_011': '首页-点击分享-FB',
        'tc_home_display_012': '首页-点击分享-WA',
        'tc_home_display_013': '首页-点击分享-链接',
        'tc_home_display_013_1': '首页-点击分享-CL',
        'tc_home_display_014': '首页-点击分享-更多',
        'tc_home_display_015': '首页-新手奖励弹窗展示',
        'tc_home_display_016': '首页-新手奖励-点击领取',
        'tc_home_display_017': '首页-手机号验证弹窗展示',
        'tc_home_display_018': '首页-点击发送验证码',
        'tc_home_display_019': '首页-点击重新发送验证码',
        'tc_home_display_020': '首页-手机号验证成功',
        'tc_recharge_display_001': '充值页展示',
        'tc_recharge_trade_002': '充值页-点击赠送',
        'tc_recharge_buy_003': '充值页-点击购买',
        'tc_recharge_buy_suc_004': '充值页-购买成功',
        'tc_recharge_buy_fail_005': '充值页-购买失败',
        'tc_recharge_history_006': '充值页-购买历史',
        'tc_trade_display_001': '赠送页展示',
        'tc_trade_history_002': '赠送页-赠送历史',
        'tc_trade_send_003': '赠送页-确定赠送',
        'tc_trade_suc_004': '赠送页-赠送成功',
        'tc_trade_fail_005': '赠送页-赠送失败',
        'task_float_click_001': '点击新手任务浮窗',
        'task_disp_002': '展示新手任务',
        'task_share_click_003': '点击分享按钮',
        'task_invite_click_004': '点击邀请按钮',
        'task_bonus_click_day1_005': '点击领取奖励1',
        'task_bonus_click_day2_006': '点击领取奖励2',
        'task_bonus_click_day3_007': '点击领取奖励3',
        'task_bonus_click_day4_008': '点击领取奖励4',
        'task_bonus_click_day5_009': '点击领取奖励5',
        'task_share_click_wa_010': '分享任务点击Whatsapp',
        'task_share_click_fb_011': '分享任务点击Facebook',
        'task_share_click_copy_012': '分享任务点击Copy&Send',
        'task_share_click_more_013': '分享任务点击More',
        'task_share_click_cl_014': '分享任务点击Copy Link',
        'task_share_click_any_015': '任意分享按钮',
        'tcp_home_click_log in_016':'首页-点击"登录"按钮',
        'tcp_home_otp verify_017':'首页-弹出otp验证弹窗',
        'tcp_home_otp verify_click_verify_018':'首页-otp验证弹窗-点击验证',
        'tcp_home_otp verify_click_cancel_019':'首页-otp验证弹窗-点击取消',
        'tcpa_w/d_click_select':'点击提现账号选择',
        'tcpa_w/d_click_add':'点击添加账号',
        'tcpa_w/d_popup_listbox':'弹出账户列表选择',
        'tcpa_w/d_listbox_click_select':'账户列表选择点击选择账户',
        'tcpa_w/d_listbox_click_add':'账户列表选择点击添加账号',
        'tcpa_w/d_listbox_click_update':'账户列表选择点击编辑账户',
        'tcpa_w/d_listbox_popup_otp':'账户列表弹出otp验证',
        'tcpa_w/d_Refer & Earn':'进入账户编辑选择页面',
        'tcpa_w/d_disp':'提现页面展示',
        'tcpa_w/d_(n)_cfm':'点击确认提现',
        'tcpa_w/d_(n)_suc':'提现成功',
        'tcpa_w/d_(n)_fail':'提现失败',

    };

    static getName(key) {
        return key + '_' + (Logger.keyNames[key] || '');
    }

    info(key, value) {
        let v = Object.assign({}, value||{})
        console.log('try to report event:', Logger.getName(key), v);
        if (production && analytics) {
            // 跳过预加载请求时的上报
            if (window.isPreload) {
                console.log('skip to report for preload');
                return;
            }
            let eventName = Logger.getName(key);
            analytics.logEvent(eventName, v);
            console.log(eventName, v);
            let channelId = localStorage.getItem('channelId', '');
            // 3100003渠道特殊上报逻辑
            if (channelId == '3100003') {
                eventName = '3100003_' + eventName;
                analytics.logEvent(eventName, v);
                console.log(eventName, v);
            }
            return;
        }
    }
}

export default new Logger();
