
import axios from 'axios';
import Cookie from '@/utils/cookie';
import { production } from '../../config';
const http = axios.create({
    // baseURL: production ? 'https://api.tcvg.pw/vgclient' : 'http://192.168.1.163:8790',
    // baseURL: production ? 'http://192.168.1.6:8790' : 'http://192.168.1.163:8790',
    // baseURL: production ? 'http://2.server:8790' : 'http://192.168.1.170:8790',
    baseURL: production ? 'https://luckydog-api.taurus.cash' : 'http://192.168.1.170:8790',
    timeout: 30000,
    responseType: "json",
    withCredentials: true,
    headers: {
        "Content-Type": "application/json;charset=utf-8",
    }
});

http.interceptors.request.use(config => {
    let token = Cookie.getCookie('token');
    if (token) {
        config.headers['token'] = token;
    }
    if (!production) {
        config.url = config.url.replace('', '');
    }
    return config;
});

const post=(url,data)=>{
    return new Promise((resolve,reject) => {
        http.post(url,data).then((res)=>{
            resolve(res)
        }).catch((e)=>{
            reject(e)
        })
    })
};
const get=(url,data)=>{
    return new Promise((resolve,reject) => {
        let param = data?Array.from(Object.entries(data)).map(([key,value])=>key+"="+value).join("&"):"";
        let combineUrl=url+"?"+param;
        http.get(combineUrl).then((res)=>{
            resolve(res)
        }).catch((e)=>{
            reject(e)
        })
    })
};
const request=http;
export default {
    post,
    get,
    request
};
