export default {
    //home页 头部
    header: {
        login: {},
        unLogin: {
            loginTip: 'Withdraw cash after signup',
            loginBtn: 'Sign in '
        }
    },
    lottery: {
        unStart: {
            tip1: "Lucky guys can earn maximum",
            tip2:"₹999 CASH"
        },
        result: {
            lastTimeShareGet: "Cash Earned from Last Invitation：",
            amount: "Available cash amount",
            canWithdraw1: "₹200 can be withdrawn",
            canWithdraw2: "₹10 can be withdrawn",
        }
    },
    bottom: {
        lotteryBtn: {
            desc: "Click SPIN to get higher withdrawal limit.",
            detail: "Records",
            rules: "Rules"
        }
    },
    popUp: {
        login: {
            login: "Please Sign in",
            phone: "Phone number",
            input: "Please enter phone number",
            upiInput: "Please enter upi number",
            agree: "I agree",
            term: "the Terms of Use",
            and: "&",
            policy: "Privacy Policy",
            sure: "Correct",
            detail: "Unregistered users will be registered on Taurus automatically"
        },
        canWithdraw1: {
            sure: "Withdrawal Available Now ",
            detail: "Minimum withdrawal amount had been reached. Go withdraw your cash!",
            goTo: "Withdraw Cash"
        },
        launch: {
            success: "Withdrawal submitted.",
            desc: "Awesome! Another spin are awarded, because your referrals are of high quality. Go spin and withdraw cash!",
            start: "Spin to Win Cash "
        },
        warm: {
            tip: "Friendly Notice",
            desc1: "Share your referral link. Earn cash forever if your friends register and play. ",
            desc2: "Notice: the minimum withdrawal amount had increased to ₹1000!",
            desc3: "Got it"
        },
        share: {
            detail: "Share Successfully ",
            distance1: "Only ",
            distance2: "away from withdrawal.",
            invite: "Invite More"
        },
        getRupeeAgain: {
            header: "Congratulations",
            desc: "Congratulations!  Cash was added to your balance. One step closer to withdraw!",
            share: "Invite friends and earn cash",
        }
    },
    bottomBtn: {
        draw: {
            how: "How to make money withdraw:",
            way1: "1. Share with link to friends, friends download.",
            way2: "2. Invited friends can get up to 30% reward for."
        }
    },
    income: {
        detail: "Cash Records ",
        date: "Date",
        income: "Cash"
    },
    rule:{
        step:"Rules",
        step1:"Earn cash by sharing your referral link to friends. Cash will be credited to your balance immediately after friends' registration. Go invite friends and earn cash right now!",
        step2:"Minimum withdrawal amount is required. You are not able to withdraw if your balance doesn’t reach certain amount.",
        step3:"Any account with forbidden behaviors detected will be blocked permanently. Taurus is not responsible for any loss caused by the account blocking."
    }

}
