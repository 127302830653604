import async from "@/api/axios/request"
import  home from "@/api/url/home"

export default {
    // 首次抽奖
    firstRoundDraw(data) {
        return async.post(home.firstRoundDraw, data);
    },
    // 首次抽奖
    secondRoundDraw(data) {
        return async.post(home.secondRoundDraw, data);
    },
    //获取属于第几轮的具体状态
    checkState(data){
        return async.post(home.checkStateUrl, data);
    },
    //收入明细
    incomeDetail(data){
        return async.post(home.incomeDetailUrl, data);
    },
    shareGetAnotherRupee(data){
        return async.post(home.shareGetAnotherRupeeUrl, data);
    }
}
