import Vue from 'vue';
import App from './App.vue';

import VueRouter from 'vue-router';
Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes: [
        // {
        //     path: '/',
        //     component: () => App,
        //     meta: {
        //         keepAlive: true // 需要被缓存
        //     }
        // },
        {
            path: '/index.html',
            component: () => App,
            meta: {
                keepAlive: true // 需要被缓存
            }
        },
        {
            path: '/home',
            component: () => import('./views/Home'),
            meta: {
                keepAlive: false // 不需要被缓存
            }
        },
        {
            path: '/customer',
            component: () => import('./views/customer'),
            meta: {
                keepAlive: false // 不需要被缓存
            }
        },
        {
            path: '/income',
            name: 'IncomeDetail',
            component: () => import('./views/IncomeDetail.vue')
        },
        {
            path: '/rule',
            name: 'RuleDesc',
            component: () => import('./views/RuleDesc.vue')
        },
        // {
        //     path: '/download',
        //     name: 'DownloadApp',
        //     component: () => import('./views/downloadApp.html')
        // },
        {
            path: '/withdraw',
            component: () => import('./views/withdraw'),
            meta: {
                keepAlive: false // 不需要被缓存
            }
        },
        {
            path: '/withdrawBefore',
            component: () => import('./views/withdraw-before.vue'),
            meta: {
                keepAlive: false // 不需要被缓存
            }
        },
        {
            path: '/withdrawAdd',
            component: () => import('./views/withdraw-add.vue'),
            meta: {
                keepAlive: false // 不需要被缓存
            }
        },
        {
            path: '/withdrawModify',
            component: () => import('./views/withdraw-modify.vue'),
            meta: {
                keepAlive: false // 不需要被缓存
            }
        },
        {
            path: '*',
            redirect: '/home'
        }
    ]
});

import VueClipboard from 'vue-clipboard2';

Vue.use(VueClipboard);

import i18n from '@/i18n';

import logger from '@/utils/logger';

Vue.prototype.$log = logger;

Vue.config.productionTip = false;

let padding = function(d) {
    return d >= 10 ? `${d}` : `0${d}`;
}
let monthes = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', ];

Vue.filter('formatDate', d => {
    return `${monthes[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
});
Vue.filter('formatTime', d => {
    return `${padding(d.getHours())}:${padding(d.getMinutes())}:${padding(d.getSeconds())}`;
});

Vue.filter('formatNum', d => {
    return (d || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
});

Vue.filter('formatPrice', d => {
    d = (d || 0).toFixed(2);
    let nums = d.split('.', 2);
    // return nums[0].replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + '.' + nums[1];
    return nums[0].replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
});

import message from 'ant-design-vue/lib/message';
message.config({
    duration: 3,
    maxCount: 1
});

import { Radio } from 'ant-design-vue';
// import 'ant-design-vue/lib/radio/style/index.css';
import '@/assets/css/antv-radio.css';
Vue.use(Radio);

import { Drawer } from 'ant-design-vue';
import 'ant-design-vue/lib/drawer/style/css'
Vue.use(Drawer);

import { Carousel, CarouselItem } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.component(Carousel.name, Carousel);
Vue.use(CarouselItem);


import { Tabs, TabPane,  } from 'element-ui';
Vue.component(Tabs.name, Tabs);
Vue.use(TabPane);

// import { Drawer  } from 'element-ui';
// Vue.use(Drawer);

import VueLazyload from 'vue-lazyload';

Vue.use(VueLazyload, {
    attempt: 1
});

// import  axios from '@/api/axios/request';
// let request=axios.request;
import http from "@/api/index"
Vue.prototype.$http = http;

//状态管理
import Vuex from 'vuex';
Vue.use(Vuex);
const store = new Vuex.Store({
    state: {
        balance: 0,
        user: {},
    },
    mutations: {
        setBalance(state, balance) {
            state.balance = balance;
        },
        setUser(state, user) {
            state.user = user;
        },

    }
});

new Vue({
    store,
    router,
    i18n,
    render: h => h(App)
}).$mount('#app');



